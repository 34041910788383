import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useInView } from "react-intersection-observer"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Projects from "../../components/projects"

const PuebloSonideroMedia = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const { PSMCloudinary } = useStaticQuery(graphql`
    query {
      PSMCloudinary: allCloudinaryMedia(
        filter: { public_id: { regex: "/rgrq/psm/" } }
      ) {
        edges {
          node {
            secure_url
          }
        }
      }
    }
  `)

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <Layout>
      <Header />
      <div className="project">
        <div className="container-fluid subheader">
          <div className="row">
            <div className="col-lg-4">
              <h2>06. Pueblo Cumbiambero: Installation </h2>
            </div>
            <div className="offset-lg-1 col-lg-3">
              <h2>2019</h2>
            </div>
            <div className="col-lg-4">
              <h2>Transmedia, Sound Design</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-12 hero hero-pueblosonideromedia">
              <div className="hero-video-container">
                <video className="hero-video" autoPlay loop muted playsInline>
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607114242/rgrq/static/pueblosonideromediatecture/01_ubo4wb.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div className={`hero-footer ${inView}`}>
                <div className="row justify-content-between align-items-end">
                  <div className="col-lg-5 d-none d-md-block">
                    <h2>
                      <Link to="/projects/pueblo-sonidero">
                        Previous Project(05) ↖
                      </Link>
                    </h2>
                    <h2>
                      <Link to="/projects/mola">Next Project(07) ↗</Link>
                    </h2>
                  </div>
                  <div className="col-md-3 d-none d-md-block">
                    <h2>
                      <Link to="/">rg-rq.com</Link>
                    </h2>
                  </div>
                  <div className="offset-md-2 col-md-2">
                    <button onClick={scrollTop}>
                      <h2>
                        Back up
                        {inView ? (
                          <img
                            className="img-fluid"
                            src="/Up.svg"
                            alt="cheveron icon"
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src="/Up-white.svg"
                            alt="cheveron icon"
                          />
                        )}
                      </h2>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid content">
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Overview</p>
            </div>
            <div className="col-lg-7">
              <p>
                One of the great Sonidero masters of Monterrey, El Señor Don
                Gabriel Dueñez, also known as Sonidero Nacional o Sonido Dueñez,
                is celebrated with an installation that takes us from Colombia
                to Monterrey.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  className="embed-responsive-item"
                  loop
                  controls
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607114249/rgrq/static/pueblosonideromediatecture/02_elqbbf.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={PSMCloudinary.edges[0].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={PSMCloudinary.edges[1].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={PSMCloudinary.edges[2].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={PSMCloudinary.edges[3].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={PSMCloudinary.edges[4].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={PSMCloudinary.edges[5].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={PSMCloudinary.edges[6].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607114239/rgrq/static/pueblosonideromediatecture/10_nhyhfg.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={PSMCloudinary.edges[7].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={PSMCloudinary.edges[8].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={PSMCloudinary.edges[9].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={PSMCloudinary.edges[10].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={PSMCloudinary.edges[11].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <div className="embed-responsive embed-responsive-1by1">
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607114236/rgrq/static/pueblosonideromediatecture/16_u4mpbg.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="embed-responsive embed-responsive-1by1">
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607114240/rgrq/static/pueblosonideromediatecture/17_jfkph7.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="embed-responsive embed-responsive-1by1">
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607114237/rgrq/static/pueblosonideromediatecture/18_p6f1ha.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Special Thank You</p>
            </div>
            <div className="col-lg-7">
              <p>Sabotaje Media (@sabotaje.media)</p>
              <p>Gabriel Dueñez (@sonido.duenez)</p>
              <p>Mazzie Miles (Professor)</p>
              <p>Ivan Cruz (Professor)</p>
              <p>Taka Iwai</p>
              <p>Rolando Castillo </p>
              <p>Yair Sarmiento </p>
              <p>Yasodari Sanchez</p>
              <p>Mas Exitos con Ganas (Dub Lab)</p> 
            </div>
          </div>
        </div>
      </div>
      <div ref={ref}></div>
      <Projects />
    </Layout>
  )
}
export default PuebloSonideroMedia
